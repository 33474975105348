/*
 * Src Messages
 *
 * This contains all the text for the src.
 */

import { defineMessages } from 'react-intl';

export const scope = 'src';

export default defineMessages({
  requiredField: {
    id: `${scope}.requiredField`,
    defaultMessage: 'required',
  },
  notFound: {
    id: `${scope}.notFound`,
    defaultMessage: 'Not found',
  },
  notTypeField: {
    id: `${scope}.notTypeField`,
    defaultMessage: 'Must be a valid {type}',
  },
  integerField: {
    id: `${scope}.integerField`,
    defaultMessage: 'Must be an integer number',
  },
  positiveField: {
    id: `${scope}.positiveField`,
    defaultMessage: 'Must be a positive number',
  },
  minValueField: {
    id: `${scope}.minValueField`,
    defaultMessage: 'Must be greater than or equal to {min}',
  },
  maxValueField: {
    id: `${scope}.maxValueField`,
    defaultMessage: 'Must be less than or equal to {max}',
  },
  minDateField: {
    id: `${scope}.minDateField`,
    defaultMessage: 'Must be on or later than {min}',
  },
  maxDateField: {
    id: `${scope}.maxDateField`,
    defaultMessage: 'Must be on or earlier than {max}',
  },
});
