import styled from 'styled-components';

import ToggleButton from '@mui/material/ToggleButton';

import { colors } from 'src/utils/customColors';

export const Styled = {
  ToggleButton: styled(ToggleButton)({
    borderColor: colors.steelGrey,
    marginLeft: 0,
    '&.Mui-selected, &.Mui-selected:hover': {
      backgroundColor: colors.blue,
    },
    '&.isError': {
      borderColor: colors.red,
    },
  }),
};
