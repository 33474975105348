import { useIntl } from 'react-intl';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { API } from 'src/reactQuery/Api';
import { postRequest } from 'src/utils/request';
import { RequestSimplifiedData } from 'src/types/requestData';
import { useMessageHandler } from 'src/utils/hooks/useMessageHandler';
import messages from 'src/pages/GatekeeperPage/components/EvaluationFormModal/messages';
import { QUERY_KEYS } from 'src/reactQuery/QueryKeys';

export type PrescreeningParamsType = {
  email: string;
  vetoed?: boolean;
  mindset?: number;
  impression?: number;
  expression?: number;
  vetoComment?: string;
  germanSpeaker?: boolean;
  comprehension?: number;
  interviewMethod: string;
};

const createPrescreening = async ({
  candidateUuid,
  ...prescreenigProps
}: PrescreeningParamsType & {
  candidateUuid: string;
}) => {
  const params = {
    prescreening: {
      interview_method: prescreenigProps.interviewMethod,
      german_speaker: prescreenigProps.germanSpeaker,
      mindset: prescreenigProps.mindset,
      impression: prescreenigProps.impression,
      comprehension: prescreenigProps.comprehension,
      expression: prescreenigProps.expression,
      vetoed: prescreenigProps.vetoed,
      veto_comment: prescreenigProps.vetoComment,
    },
  };

  const { data } = await postRequest<RequestSimplifiedData>(
    API.POST.createPrescreening(candidateUuid),
    params,
  );

  return data;
};

export const useCreatePrescreening = () => {
  const client = useQueryClient();
  const { formatMessage } = useIntl();
  const { handleError, handleSuccess } = useMessageHandler();

  return useMutation({
    mutationFn: createPrescreening,
    onSuccess: ({ data: { uuid } }) => {
      handleSuccess(formatMessage(messages.preScreeningCreated), 1000);

      client.invalidateQueries({ queryKey: [QUERY_KEYS.USER_ACCOUNTS] });
      client.invalidateQueries({ queryKey: [QUERY_KEYS.PRE_SCREENING, uuid] });
    },
    onError: handleError,
  });
};
