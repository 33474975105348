import { useCallback, useMemo, useRef, useState } from 'react';

import { useIntl } from 'react-intl';

import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid2';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';

import DataTable from 'src/components/DataTable';
import PageLayout from 'src/components/PageLayout';
import { isOlderThanThreeDays } from 'src/utils/datetime';
import SearchFilterBar, {
  resolveQuickSearch,
  type QuickFiltersType,
} from 'src/components/SearchFilterBar';
// import usePageContainer from 'src/utils/hooks/usePageContainer';

// SVGs
import { ReactComponent as PlayIcon } from 'src/images/Play.svg';
import { ReactComponent as DisablePlay } from 'src/images/DisablePlay.svg';
import { ReactComponent as PencilIcon } from 'src/images/Pencil.svg';
import { ReactComponent as PersonEditIcon } from 'src/images/PersonEdit.svg';
import { ReactComponent as TransitionPushIcon } from 'src/images/TransitionPush.svg';
import { ReactComponent as AssignmentTurnedInIcon } from 'src/images/AssignmentTurnedIn.svg';

import EvaluationFormModal from './components/EvaluationFormModal';
import PreRegisterFormModal from './components/PreRegisterFormModal';
import { useUpdatePrescreening } from './api/mutations/useUpdatePrescreening';

import messages from './messages';
import { useGetUserAccounts } from './api';
import { useCreatePrescreening } from './api';
import { useCreateUserAccount, useUpdateUserAccount } from './api';
import ConfirmationDialog from 'src/components/ConfirmationDialog';
import { useUpdateGrantStudentAccess } from './api/mutations/useUpdateGrantStudentAccess';

const navTabs: Array<{ value: UCM.CandidateStatus; label: string }> = [
  {
    value: 'pre_registered',
    label: 'Pre-registered',
  },
  {
    value: 'app_registered',
    label: 'App-registered',
  },
  {
    value: 'prescreening_missing',
    label: 'Pre-screen missing',
  },
  {
    value: 'queued',
    label: 'Queued',
  },
];

export default function GatekeeperPage() {
  const { formatMessage } = useIntl();
  // const [message] = usePageContainer();

  const { mutate: createUserAccount } = useCreateUserAccount();
  const { mutate: updateUserAccount } = useUpdateUserAccount();
  const { mutate: createPrescreening } = useCreatePrescreening();
  const { mutate: updatePrescreening } = useUpdatePrescreening();
  const { mutate: updateStudentAccess } = useUpdateGrantStudentAccess();

  const [activeTab, setActiveTab] = useState<UCM.CandidateStatus>(
    navTabs[0].value,
  );
  const [filters, setFilters] = useState<Record<string, any>>({});
  const [params, setParams] = useState<Record<string, any>>({ page: 1 });

  const { data: userAccounts, isFetching: isFetchingUserAccounts } =
    useGetUserAccounts(activeTab, params, resolveQuickSearch(filters));

  const quickFilters: Array<QuickFiltersType> = useRef([
    {
      autoFocus: true,
      id: 'quick_search',
      type: 'text' as QuickFiltersType['type'],
      placeholder: formatMessage(messages.searchInputPlaceholder),
    },
  ]).current;

  const columns: Record<
    UCM.CandidateStatus,
    Array<{ id: keyof UCM.UserAccountType | string }>
  > = useMemo(
    () => ({
      pre_registered: [
        {
          id: 'email',
          label: formatMessage(messages.emailColumnLabel),
        },
        {
          id: 'phoneNumber',
          label: formatMessage(messages.phoneColumnLabel),
          render: (phoneNumber: string | null) => {
            if (phoneNumber == null) return '-';
            return <span>{phoneNumber}</span>;
          },
        },
        {
          id: 'createdAt',
          label: formatMessage(messages.leadSinceColumnLabel),
          render: (createdAt: string) => {
            const isOlder = isOlderThanThreeDays(createdAt);
            return (
              <span style={{ color: isOlder ? 'orangeRed' : 'inherit' }}>
                {createdAt}
              </span>
            );
          },
        },
        // TODO - BE not supported for this yet
        // {
        //   id: 'campaign',
        //   label: formatMessage(messages.campaignColumnLabel),
        //   render: () => '-',
        // },
        {
          id: 'prescreening',
          label: formatMessage(messages.statusColumnLabel),
          render: (prescreening: UCM.UserAccountType['prescreening']) => {
            if (prescreening == null) return '-';
            return <span>{prescreening.status ?? '-'}</span>;
          },
        },
        {
          id: 'action',
          align: 'center',
          label: '',
          render: (_: Object, item: UCM.UserAccountType) => {
            return (
              <Grid container>
                <Grid size={{ lg: 6, md: 6, sm: 12 }}>
                  <PreRegisterFormModal
                    isEdit
                    userUuid={item.userUuid}
                    onUpdate={updateUserAccount}
                    triggerButton={(handleOpen) => (
                      <Tooltip title={formatMessage(messages.editPreregHint)}>
                        <IconButton onClick={handleOpen}>
                          <PersonEditIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                  />
                </Grid>

                <Grid size={{ lg: 6, md: 6, sm: 12 }}>
                  <EvaluationFormModal
                    userEmail={item.email}
                    userUuid={item.userUuid}
                    onUpdate={updatePrescreening}
                    onCreate={createPrescreening}
                    isEdit={item.prescreening != null}
                    uuid={item.prescreening?.uuid ?? ''}
                    triggerButton={(handleOpen) => {
                      const isEditMode = item.prescreening != null;
                      const iconHint = isEditMode
                        ? messages.editPrescreenHint
                        : messages.createPrescreenHint;

                      return (
                        <Tooltip title={formatMessage(iconHint)}>
                          <IconButton onClick={handleOpen}>
                            {isEditMode ? (
                              <PencilIcon />
                            ) : (
                              <AssignmentTurnedInIcon />
                            )}
                          </IconButton>
                        </Tooltip>
                      );
                    }}
                  />
                </Grid>
              </Grid>
            );
          },
        },
      ],
      app_registered: [
        {
          id: 'email',
          label: formatMessage(messages.emailColumnLabel),
        },
        {
          id: 'phoneNumber',
          label: formatMessage(messages.phoneColumnLabel),
          render: (phoneNumber: string | null) => {
            if (phoneNumber == null) return '-';
            return <span>{phoneNumber}</span>;
          },
        },
        {
          id: 'createdAt',
          label: formatMessage(messages.accountSinceColumnLabel),
          render: (createdAt: string) => {
            const isOlder = isOlderThanThreeDays(createdAt);
            return (
              <span style={{ color: isOlder ? 'orangeRed' : 'inherit' }}>
                {createdAt}
              </span>
            );
          },
        },
        {
          id: 'prescreening',
          label: formatMessage(messages.evaluatedSinceColumnLabel),
          render: (prescreening: UCM.UserAccountType['prescreening']) => {
            if (prescreening == null || prescreening.createdAt == null)
              return '-';

            const isOlder = isOlderThanThreeDays(prescreening.createdAt);
            return (
              <span style={{ color: isOlder ? 'orangeRed' : 'inherit' }}>
                {prescreening.createdAt}
              </span>
            );
          },
        },
        {
          id: 'status',
          label: formatMessage(messages.statusColumnLabel),
          render: (_: Object, { prescreening }: UCM.UserAccountType) => {
            if (prescreening == null) return '-';
            return <span>{prescreening.status ?? '-'}</span>;
          },
        },
        {
          id: 'action',
          label: '',
          align: 'center',
          maxWidth: '50px',
          render: (_: Object, item: UCM.UserAccountType) => {
            return (
              <Grid container>
                <Grid size={{ lg: 6, md: 6, sm: 12 }}>
                  <EvaluationFormModal
                    userEmail={item.email}
                    userUuid={item.userUuid}
                    onUpdate={updatePrescreening}
                    onCreate={createPrescreening}
                    isEdit={item.prescreening != null}
                    uuid={item.prescreening?.uuid ?? ''}
                    triggerButton={(handleOpen) => {
                      const isEditMode = item.prescreening != null;
                      const iconHint = isEditMode
                        ? messages.editPrescreenHint
                        : messages.createPrescreenHint;

                      return (
                        <Tooltip title={formatMessage(iconHint)}>
                          <IconButton onClick={handleOpen}>
                            {isEditMode ? (
                              <PencilIcon />
                            ) : (
                              <AssignmentTurnedInIcon />
                            )}
                          </IconButton>
                        </Tooltip>
                      );
                    }}
                  />
                </Grid>

                <Grid size={{ lg: 6, md: 6, sm: 12 }}>
                  {forceAccessComponent(item.userUuid)}
                </Grid>
              </Grid>
            );
          },
        },
      ],
      prescreening_missing: [
        {
          id: 'email',
          label: formatMessage(messages.emailColumnLabel),
        },
        {
          id: 'phoneNumber',
          label: formatMessage(messages.phoneColumnLabel),
          render: (phoneNumber: string | null) => {
            if (phoneNumber == null) return '-';
            return <span>{phoneNumber}</span>;
          },
        },
        {
          id: 'status',
          label: formatMessage(messages.statusColumnLabel),
          render: (_: Object, { prescreening }: UCM.UserAccountType) => {
            if (prescreening == null) return '-';
            return <span>{prescreening.status ?? '-'}</span>;
          },
        },
        {
          id: 'basicProfileCompletedAt',
          label: formatMessage(messages.baseProfileSinceColumnLabel),
          render: (
            completedAt: UCM.UserAccountType['basicProfileCompletedAt'],
          ) => {
            if (completedAt == null) return '-';

            const isOlder = isOlderThanThreeDays(completedAt);
            return (
              <span style={{ color: isOlder ? 'orangeRed' : 'inherit' }}>
                {completedAt}
              </span>
            );
          },
        },
        // TODO - BE not supported for this yet
        // {
        //   id: 'user_potential',
        //   label: formatMessage(messages.userProfileColumnLabel),
        //   render: () => '-',
        // },
        {
          id: 'video',
          align: 'center',
          label: formatMessage(messages.videoColumnLabel),
          render: (_: Object, { prescreening }: UCM.UserAccountType) => {
            console.log(prescreening);

            if (prescreening == null || prescreening.url == null)
              return (
                <Tooltip title={formatMessage(messages.videoUrlHint)}>
                  <DisablePlay />
                </Tooltip>
              );

            return (
              <Tooltip title={formatMessage(messages.videoUrlHint)}>
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  href={prescreening.url}
                >
                  <PlayIcon />
                </Link>
              </Tooltip>
            );
          },
        },
        {
          id: 'action',
          align: 'center',
          maxWidth: '60px',
          label: formatMessage(messages.evaluateColumnLabel),
          render: (_: Object, item: UCM.UserAccountType) => {
            return (
              <Grid container>
                <Grid size={{ lg: 6, md: 6, sm: 12 }}>
                  <EvaluationFormModal
                    userEmail={item.email}
                    userUuid={item.userUuid}
                    onUpdate={updatePrescreening}
                    onCreate={createPrescreening}
                    isEdit={item.prescreening != null}
                    uuid={item.prescreening?.uuid ?? ''}
                    triggerButton={(handleOpen) => {
                      const isEditMode = item.prescreening != null;
                      const iconHint = isEditMode
                        ? messages.editPrescreenHint
                        : messages.createPrescreenHint;

                      return (
                        <Tooltip title={formatMessage(iconHint)}>
                          <IconButton onClick={handleOpen}>
                            {isEditMode ? (
                              <PencilIcon />
                            ) : (
                              <AssignmentTurnedInIcon />
                            )}
                          </IconButton>
                        </Tooltip>
                      );
                    }}
                  />
                </Grid>

                <Grid size={{ lg: 6, md: 6, sm: 12 }}>
                  {forceAccessComponent(item.userUuid)}
                </Grid>
              </Grid>
            );
          },
        },
      ],
      queued: [
        {
          id: 'email',
          label: formatMessage(messages.emailColumnLabel),
        },
        {
          id: 'phoneNumber',
          label: formatMessage(messages.phoneColumnLabel),
          render: (phoneNumber: string | null) => {
            if (phoneNumber == null) return '-';
            return <span>{phoneNumber}</span>;
          },
        },
        {
          id: 'prescreening',
          label: formatMessage(messages.evaluatedSinceColumnLabel),
          render: (prescreening: UCM.UserAccountType['prescreening']) => {
            if (prescreening == null || prescreening.createdAt == null)
              return '-';

            const isOlder = isOlderThanThreeDays(prescreening.createdAt);
            return (
              <span style={{ color: isOlder ? 'orangeRed' : 'inherit' }}>
                {prescreening.createdAt}
              </span>
            );
          },
        },
        {
          id: 'evaluatedBy',
          label: formatMessage(messages.evaluatedByColumnLabel),
          render: (_: Object, { prescreening }: UCM.UserAccountType) => {
            if (prescreening == null) return '-';
            return <span>{prescreening.verifiedBy ?? '-'}</span>;
          },
        },
        {
          id: 'reason',
          label: formatMessage(messages.reasonColumnLabel),
          render: () => '-',
        },
        {
          id: 'action',
          label: '',
          maxWidth: '30px',
          render: (_: Object, item: UCM.UserAccountType) => {
            return (
              <Grid container>
                <Grid size={{ lg: 6, md: 6, sm: 12 }}>
                  <EvaluationFormModal
                    userEmail={item.email}
                    userUuid={item.userUuid}
                    onUpdate={updatePrescreening}
                    onCreate={createPrescreening}
                    isEdit={item.prescreening != null}
                    uuid={item.prescreening?.uuid ?? ''}
                    triggerButton={(handleOpen) => {
                      const isEditMode = item.prescreening != null;
                      const iconHint = isEditMode
                        ? messages.editPrescreenHint
                        : messages.createPrescreenHint;

                      return (
                        <Tooltip title={formatMessage(iconHint)}>
                          <IconButton onClick={handleOpen}>
                            {isEditMode ? (
                              <PencilIcon />
                            ) : (
                              <AssignmentTurnedInIcon />
                            )}
                          </IconButton>
                        </Tooltip>
                      );
                    }}
                  />
                </Grid>

                <Grid size={{ lg: 6, md: 6, sm: 12 }}>
                  {forceAccessComponent(item.userUuid)}
                </Grid>
              </Grid>
            );
          },
        },
      ],
    }),
    [activeTab],
  );

  const forceAccessComponent = useCallback((candidateUuid: string) => {
    return (
      <ConfirmationDialog
        maxWidth="sm"
        onConfirm={() => updateStudentAccess(candidateUuid)}
        message={formatMessage(messages.accessGrantedConfirmMessage)}
      >
        {(handleOpen) => (
          <Tooltip title={formatMessage(messages.forceAccessHint)}>
            <IconButton onClick={handleOpen}>
              <TransitionPushIcon />
            </IconButton>
          </Tooltip>
        )}
      </ConfirmationDialog>
    );
  }, []);

  const handleSearch = useCallback((filters: Record<string, any>) => {
    setFilters(filters);
  }, []);

  const handlePagination = useCallback((event: unknown, page: number) => {
    setParams({ page: page + 1 });
  }, []);

  return (
    <PageLayout
      title="Gatekeeper"
      tabs={navTabs}
      activeTab={activeTab}
      setActiveTab={(tab: string) => {
        setActiveTab(tab as UCM.CandidateStatus);
        setParams({ page: 1 });
      }}
    >
      <Grid container spacing={2} width="100%">
        <Grid size={3}>
          <SearchFilterBar fields={quickFilters} onSearch={handleSearch} />
        </Grid>

        <Grid
          size={9}
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
        >
          <Grid container spacing={2} justifyContent="flex-end">
            <Grid size={4}>
              {activeTab !== 'queued' ? (
                <EvaluationFormModal
                  isEdit={false}
                  onCreate={createPrescreening}
                />
              ) : null}
            </Grid>

            <Grid size={6}>
              {activeTab === 'pre_registered' ? (
                <PreRegisterFormModal
                  isEdit={false}
                  onCreate={createUserAccount}
                />
              ) : null}
            </Grid>
          </Grid>
        </Grid>

        <Grid size={{ xs: 12 }}>
          <DataTable
            columns={columns[activeTab]}
            rows={userAccounts?.data ?? []}
            pagination={userAccounts?.meta}
            onPaginate={handlePagination}
            isLoading={isFetchingUserAccounts}
          />
        </Grid>
      </Grid>
    </PageLayout>
  );
}
