/*
 * Mice Field Messages
 *
 * This contains all the text for the Mice Field component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'src.components.MiceField';

export default defineMessages({
  bad: {
    id: `${scope}.bad`,
    defaultMessage: 'Bad',
  },
  perfect: {
    id: `${scope}.perfect`,
    defaultMessage: 'Perfect',
  },
});
