/*
 * Confirm Modal Messages
 *
 * This contains all the text for the Confirm Modal component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'src.components.ConfirmModal';

export default defineMessages({
  submitButtonLabel: {
    id: `${scope}.submitButtonLabel`,
    defaultMessage: 'Submit',
  },
});
