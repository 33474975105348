import { useQuery } from '@tanstack/react-query';

import { locale } from 'src/config';
import { API } from 'src/reactQuery/Api';
import { getRequest } from 'src/utils/request';
import { QUERY_KEYS } from 'src/reactQuery/QueryKeys';

import type { RequestDataArray } from 'src/types/requestData';

export type ContentLanguageType = 'en' | 'de';

const mapLanguageLevelOptions = (
  option: UCM.LanguageLevelOptionRawType,
): UCM.LanguageLevelOptionType => ({
  value: option.code,
  label: option.name,
});

const getLanguageLevelOptions = async (
  contentLanguage: ContentLanguageType,
) => {
  const { data } = await getRequest<
    RequestDataArray<UCM.LanguageLevelOptionRawType>
  >(API.GET.languageLevelOptions, {
    headers: {
      Accept: 'application/vnd.falcon.v4+json',
      'Content-Language': contentLanguage,
    },
  });
  return data.data.map(({ attributes }) => mapLanguageLevelOptions(attributes));
};

export const useGetLanguageLevelOptions = (
  contentLanguage?: ContentLanguageType,
  config: { enabled?: boolean } = { enabled: true },
) => {
  const language = contentLanguage ?? locale;

  return useQuery({
    queryKey: [QUERY_KEYS.LANGUAGE_LEVEL_OPTIONS, language],
    queryFn: () => getLanguageLevelOptions(language),
    staleTime: 1000 * 60,
    ...config,
  });
};
