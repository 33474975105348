import { QueryFunctionContext, useQuery } from '@tanstack/react-query';

import { API } from 'src/reactQuery/Api';
import { getRequest } from 'src/utils/request';
import { QUERY_KEYS } from 'src/reactQuery/QueryKeys';

import type { RequestData } from 'src/types/requestData';

import messages from 'src/pages/GatekeeperPage/components/PreRegisterFormModal/messages';

const mapEmailAvailable = (
  option: UCM.EmailAvailableRawType,
): UCM.EmailAvailableType => {
  const message = (() => {
    switch (option.registration_status) {
      case 'available':
        return messages.availableStatus;
      case 'preregistered':
        return messages.preRegisteredStatus;
      default:
        return messages.registeredStatus;
    }
  })();

  return {
    message,
    userUuid: option.user_uuid,
    registrationStatus: option.registration_status,
  };
};

const checkEmail = async ({
  queryKey: [_, email],
}: QueryFunctionContext<[QUERY_KEYS, string]>) => {
  const {
    data: {
      data: { attributes },
    },
  } = await getRequest<RequestData<UCM.EmailAvailableRawType>>(
    API.GET.checkEmail,
    {
      params: { email },
      headers: { Accept: 'application/vnd.falcon.v4+json' },
    },
  );

  return mapEmailAvailable(attributes);
};

export const useGetEmailAvailable = (
  email: string,
  config: { enabled?: boolean } = { enabled: false },
) => {
  return useQuery({
    queryKey: [QUERY_KEYS.CHECK_EMAIL, email],
    queryFn: checkEmail,
    ...config,
  });
};
