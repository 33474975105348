import { useIntl } from 'react-intl';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import toInteger from 'lodash/toInteger';

import { API } from 'src/reactQuery/Api';
import { putRequest } from 'src/utils/request';
import { QUERY_KEYS } from 'src/reactQuery/QueryKeys';
import { useMessageHandler } from 'src/utils/hooks/useMessageHandler';
import messages from 'src/pages/GatekeeperPage/components/PreRegisterFormModal/messages';

import type { RequestSimplifiedData } from 'src/types/requestData';
import type { UserAccountParamsType } from './useCreateUserAccount';

const updateUserAccount = async ({
  candidateUuid,
  ...payload
}: Partial<UserAccountParamsType> & { candidateUuid: string }) => {
  const params = {
    user_account: {
      email: payload.email,
      last_name: payload.lastName,
      city_code: payload.mainCity,
      german_level: payload.german,
      first_name: payload.firstName,
      english_level: payload.english,
      birth_date: payload.dateOfBirth,
      mobile_number: payload.phoneNumber,
      mobile_calling_code: payload.phoneCode,
      occupation_code: payload.occupation,
      citizenship_country_code: payload.nationality,
      graduation_year:
        payload.graduation != '' ? toInteger(payload.graduation) : null,
    },
  };

  await putRequest<RequestSimplifiedData>(
    API.PUT.updateUserAccount(candidateUuid),
    params,
  );
};

export const useUpdateUserAccount = () => {
  const client = useQueryClient();
  const { formatMessage } = useIntl();
  const { handleError, handleSuccess } = useMessageHandler();

  return useMutation({
    mutationFn: updateUserAccount,
    onSuccess: () => {
      handleSuccess(formatMessage(messages.preRegistrationUpdated), 1000);
      client.invalidateQueries({
        queryKey: [QUERY_KEYS.USER_ACCOUNTS],
      });
    },
    onError: handleError,
  });
};
