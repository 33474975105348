/*
 * Login Page Messages
 *
 * This contains all the text for the LoginPage component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'src.pages.Auth.LoginPage';

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Login to Backstage (Staging)',
  },
  subtitle: {
    id: `${scope}.subtitle`,
    defaultMessage:
      'If you gained access to UCM Backstage (Staging), you can login with your staging account.',
  },
  emailAddressLabel: {
    id: `${scope}.emailAddressLabel`,
    defaultMessage: 'Email Address',
  },
  passwordLabel: {
    id: `${scope}.passwordLabel`,
    defaultMessage: 'Password',
  },
  signIn: {
    id: `${scope}.signIn`,
    defaultMessage: 'Sign In',
  },
  signInWithGoogle: {
    id: `${scope}.signInWithGoogle`,
    defaultMessage: 'Login with Google',
  },
});
