export const colors = {
  turquoise: '#1B728C',
  darkTurquoise: '#114358',
  coral: '#F3997B',
  yellow: '#FBBA00',
  lightYellow: '#FEF7E6',
  lighterBlue: '#F2FAFF',
  lightBlue: '#EBF6FF',
  blue: '#C2E8FF',
  white: '#FFFFFF',
  lighterGrey: '#FBFBFB',
  lightGrey: '#F6F6F6',
  mediumGrey: '#E2E2E2',
  grey: '#6F797B',
  darkGrey: '#535353',
  darkerGray: '#3F484B',
  steelGrey: '#BFC8CB',
  red: '#DE2B2B',
  green: '#0FBF00',
  lightGreen: '#C7DDD2',
  darkGreen: '#20392A',
  orange: '#FB9700',
  black: '#000000',
  darkBlue: '#001E2B',
  orangeRed: '#F64740',
  brown: '#1D1B201F',
  lightGray: '#BFC8CB',
};

export type RGB = {
  r: number;
  g: number;
  b: number;
};

export function hexToRGB(hex: string): RGB | null {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  if (result) {
    return {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16),
    };
  }
  return null;
}

export function hexToRGBA(hexColor: string, opacityLevel: number): string {
  const rgb = hexToRGB(hexColor);
  if (rgb) {
    return `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${opacityLevel})`;
  }
  return ''; // Return an empty string if the hex color is invalid
}

export function opacity(hexColor: string, level: number = 0.5): string {
  return hexToRGBA(hexColor, level);
}
