export enum QUERY_KEYS {
  // GET (list endpoint keys)
  CITY_OPTIONS = 'CITY_OPTIONS',
  USER_ACCOUNTS = 'USER_ACCOUNTS',
  COUNTRY_OPTIONS = 'COUNTRY_OPTIONS',
  OCCUPATION_OPTIONS = 'OCCUPATION_OPTIONS',
  LANGUAGE_LEVEL_OPTIONS = 'LANGUAGE_LEVEL_OPTIONS',
  INTERVIEW_METHOD_OPTIONS = 'INTERVIEW_METHOD_OPTIONS',

  // POST (Details endpoint keys)
  CHECK_EMAIL = 'CHECK_EMAIL',
  USER_ACCOUNT = 'USER_ACCOUNT',
  PRE_SCREENING = 'PRE_SCREENING',
}
