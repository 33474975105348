import React from 'react';

import TextField, { type TextFieldProps } from '@mui/material/TextField';

type TextInputProps = TextFieldProps & {
  EndIcon?: React.ElementType;
};

export default function TextInput({ EndIcon, ...props }: TextInputProps) {
  return (
    <TextField
      variant={props?.variant ?? 'standard'}
      sx={{
        input: { fontSize: 16 },
      }}
      slotProps={{
        input: {
          endAdornment: EndIcon && (
            <EndIcon style={{ width: 24, height: 24 }} />
          ),
        },
      }}
      {...props}
    />
  );
}
