import { useQuery } from '@tanstack/react-query';

import map from 'lodash/map';
import filter from 'lodash/filter';

import { API } from 'src/reactQuery/Api';
import { daysUntilToday } from 'src/utils/datetime';
import { QUERY_KEYS } from 'src/reactQuery/QueryKeys';
import { getRequest, paginationMapper, serialize } from 'src/utils/request';

import type { RequestDataArray } from 'src/types/requestData';

const mapUserAccounts = (
  userAccount: UCM.UserAccountRawType,
): UCM.UserAccountType => {
  const prescreening = userAccount.prescreening ?? null;

  const prescreeningStatusMapper: Record<
    UCM.PrescreeningStatusRawType,
    UCM.PrescreeningStatusType
  > = {
    pending: 'Pending',
    approved: 'Approved',
    rejected_on_ratings: 'MICE rejected',
    rejected_on_technicalities: 'Language Rejected',
  };

  return {
    email: userAccount.email,
    userId: userAccount.user_id,
    userUuid: userAccount.user_uuid,
    createdAt: daysUntilToday(userAccount.created_at) ?? '',
    phoneNumber:
      userAccount.phone.number === null &&
      userAccount.phone.calling_code === null
        ? null
        : `${userAccount.phone.calling_code ?? ''} ${userAccount.phone.number ?? ''}`.trim(),
    campaign: userAccount.campaign,
    userPotential: userAccount.user_potential,
    basicProfileCompletedAt: userAccount.basic_profile_completed_at
      ? daysUntilToday(userAccount.basic_profile_completed_at)
      : null,
    prescreening: prescreening
      ? {
          ...prescreening,
          status: prescreeningStatusMapper[prescreening.status],
          url: prescreening.url ?? null,
          verifiedBy: prescreening.verified_by ?? null,
          createdAt: prescreening.created_at
            ? daysUntilToday(prescreening.created_at)
            : null,
        }
      : null,
  };
};

const getUserAccounts = async <T extends UCM.CandidateStatus>(
  candidateStatus: T,
  filters: Array<Array<{ value: string; name: string }>> = [],
  params: Record<string, any>,
) => {
  const queryString = [
    serialize(params),
    ...map(filters, (filterParams) => serialize(filterParams, 'filters')),
  ];

  let query = filter(queryString, (query) => query?.length).join('&');
  query = query.length ? `?${query}` : '';

  const { data } = await getRequest<
    RequestDataArray<UCM.UserAccountRawType, UCM.MetaRawType>
  >(`${API.GET.getUserAccounts(candidateStatus)}${query}`);

  return {
    meta: paginationMapper(data.meta),
    data: data.data.map(({ attributes }) => mapUserAccounts(attributes)),
  };
};

export const useGetUserAccounts = (
  candidateStatus: UCM.CandidateStatus,
  params: Record<string, any>,
  filters: Array<{ value: string; name: string }> = [],
  config: { enabled?: boolean } = { enabled: true },
) => {
  return useQuery({
    queryKey: [QUERY_KEYS.USER_ACCOUNTS, candidateStatus, filters, params],
    queryFn: () => getUserAccounts(candidateStatus, [filters], params),
    staleTime: 1000 * 60 * 5, // Dashboards refreshed after every 5 minutes automatically
    ...config,
  });
};
