import { useIntl } from 'react-intl';

import Stack from '@mui/material/Stack';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';

import messages from './messages';
import { Styled } from './MiceField.styled';

interface MiceFieldProps {
  label: string;
  name: string;
  value: number | null | undefined;
  onChange: (name: string, value: number) => void;
  isError?: boolean;
}

// TODO - temporary solution
const OPTIONS = [
  { name: '1', value: 1 },
  { name: '2', value: 2 },
  { name: '3', value: 3 },
  { name: '4', value: 4 },
];

export default function MiceField({
  label,
  name,
  value,
  isError,
  onChange,
}: MiceFieldProps) {
  const { formatMessage } = useIntl();

  const handleChange = (newValue: number) => {
    if (newValue !== value) {
      onChange(name, newValue);
    }
  };

  return (
    <Stack direction={'row'} alignItems={'center'} sx={{ paddingBottom: 1.5 }}>
      <InputLabel
        sx={{ width: '33%', fontWeight: '600', color: '#787878' }}
        error={isError}
      >
        {label}
      </InputLabel>
      <Stack width={'33%'}>
        <Stack direction={'row'} alignItems={'center'} gap={1}>
          {OPTIONS.map((item, index) => {
            return (
              <Stack position={'relative'} key={item.value}>
                <Styled.MiceButton
                  className={`${value === item.value ? 'isActive' : ''} ${
                    isError ? 'isError' : ''
                  }`}
                  onClick={() => handleChange(item.value)}
                >
                  <Typography variant="h3" component="h3" fontSize={12}>
                    {item.name}
                  </Typography>
                </Styled.MiceButton>
                {index === 0 && (
                  <Styled.Info>{formatMessage(messages.bad)}</Styled.Info>
                )}
                {index === OPTIONS.length - 1 && (
                  <Styled.Info>{formatMessage(messages.perfect)}</Styled.Info>
                )}
              </Stack>
            );
          })}
        </Stack>
      </Stack>
    </Stack>
  );
}
