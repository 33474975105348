import { Moment } from 'moment';
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

export const DatePickerField = ({
  value,
  label,
  onChange,
  ...props
}: DatePickerProps<Moment>) => {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DatePicker
        {...props}
        value={value}
        onChange={onChange}
        label={label}
        reduceAnimations
        slotProps={{
          textField: {
            variant: 'standard',
            sx: {
              '& .MuiInputBase-root': {
                paddingRight: '2px',
              },
              width: '99%',
            },
          },
        }}
      />
    </LocalizationProvider>
  );
};
