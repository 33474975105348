import styled from 'styled-components';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { colors } from 'src/utils/customColors';
import TableContainer from '@mui/material/TableContainer';

export const Styled = {
  Container: styled(TableContainer)`
    display: flex;
    overflow: hidden;
    min-height: 400px;
    border-radius: 16px;
    flex-direction: column;
    border: 1px solid ${colors.mediumGrey};
  `,
  Cell: styled(TableCell)`
    &:not(:last-child) {
      border-right: 1px solid ${colors.mediumGrey};
    }
  `,
  Row: styled(TableRow)`
    &:nth-child(even) {
      background-color: ${colors.lightGrey};
    }
    &:nth-child(odd) {
      background-color: white;
    }
  `,
};
