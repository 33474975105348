import { useQuery } from '@tanstack/react-query';

import { API } from 'src/reactQuery/Api';
import { getRequest } from 'src/utils/request';
import { QUERY_KEYS } from 'src/reactQuery/QueryKeys';

import type { RequestData } from 'src/types/requestData';

const mapPrescreeningDetail = (
  option: UCM.PrescreeningRawType,
): UCM.PrescreeningType => ({
  email: option.email,
  uuid: option.uuid,
  userAccountId: option.user_account_id,
  interviewMethod: option.interview_method,
  status: option.status,
  externalStage: option.external_interview_stage,
  vetoed: option.vetoed,
  vetoComment: option.veto_comment,
  germanSpeaker: option.german_speaker,
  attributeScores: option.attribute_scores,
  interview: {
    id: option.interview.interview_id,
    positionId: option.interview.position_id,
    candidateUrl: option.interview.interview_candidate_url,
    managerUrl: option.interview.interview_manager_url,
  },
});

const getPrescreening = async (candidateUuid: string, uuid: string) => {
  const {
    data: {
      data: { attributes },
    },
  } = await getRequest<RequestData<UCM.PrescreeningRawType>>(
    API.GET.getPrescreening(candidateUuid, uuid),
  );

  return mapPrescreeningDetail(attributes);
};

export const useGetPrescreening = (
  candidateUuid: string,
  uuid: string,
  config: { enabled?: boolean } = { enabled: true },
) => {
  return useQuery({
    queryKey: [QUERY_KEYS.PRE_SCREENING, uuid],
    queryFn: () => getPrescreening(candidateUuid, uuid),
    ...config,
  });
};
