const GET = {
  loginGoogle: '/auth/google_oauth2/callback',
  languageLevelOptions: '/dictionaries/language_levels',
  occupationOptions: '/dictionaries/occupations',
  cityOptions: '/dictionaries/cities',
  countryOptions: '/dictionaries/countries',
  checkEmail: '/user_accounts/email_available',
  getUserAccount: (uuid: string) => `/candidates/${uuid}/user_accounts`,
  getUserAccounts: (candidateStatus: UCM.CandidateStatus) =>
    `/candidates/user_accounts/${candidateStatus}`,
  interviewMethodOptions: '/dictionaries/prescreening_methods',
  getPrescreening: (candidateUuid: string, uuid: string) =>
    `/candidates/${candidateUuid}/prescreenings/${uuid}`,
};

const POST = {
  loginUser: '/login/admin',
  createPreRegistration: '/candidates/user_accounts',
  createPrescreening: (candidateUuid: string) =>
    `/candidates/${candidateUuid}/prescreenings/`,
};

const PATCH = {};

const PUT = {
  updateUserAccount: (uuid: string) => `/candidates/${uuid}/user_accounts`,
  updatePrescreening: (candidateUuid: string, uuid: string) =>
    `/candidates/${candidateUuid}/prescreenings/${uuid}`,
  grantStudentAccess: (candidateUuid: string) =>
    `/candidates/${candidateUuid}/user_roles/grant_student_access`,
};

const DELETE = {};

export const API = {
  GET,
  PUT,
  POST,
  PATCH,
  DELETE,
};
