import React from 'react';

import Cookies from 'js-cookie';

import LoginPage from 'src/pages/Auth/LoginPage';

const accessToken = 'access_token';

export const setAccessToken = (token: string) =>
  Cookies.set(accessToken, token);

export const removeAccessToken = () => Cookies.remove(accessToken);

export const getAccessToken = () => Cookies.get(accessToken);

export const isAuthenticated = () => !!getAccessToken();

export const useRequireAuthentication = <P extends object>(
  PageComponent: React.ComponentType<P>,
) => {
  const AuthenticatedComponent = (props: P): JSX.Element => {
    // If authenticated, render the PageComponent
    if (isAuthenticated()) {
      return <PageComponent {...props} />;
    }

    return <LoginPage />;
  };

  return AuthenticatedComponent;
};
