import React from 'react';

import Stack from '@mui/material/Stack';
import InputLabel from '@mui/material/InputLabel';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import { Styled } from './CustomToggleButton.styled';

interface ToggleOptionsType {
  name: string;
  value: boolean;
}

interface CustomToggleButtonProps {
  label: string;
  value: boolean | undefined;
  name: string;
  options?: ToggleOptionsType[];
  onChange: (name: string, value: string) => void;
  isError?: boolean;
  justifyContent?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
    | 'start'
    | 'end'
    | 'left'
    | 'right';
}

// TODO - temporary solution
const DEFAULT_OPTIONS = [
  { name: 'Yes', value: true },
  { name: 'No', value: false },
];

export default function CustomToggleButton({
  label,
  value,
  name,
  options,
  onChange,
  isError,
  justifyContent = 'left',
}: CustomToggleButtonProps) {
  const onHandleChange = (
    event: React.MouseEvent<HTMLElement>,
    value: string,
  ) => {
    onChange && onChange(name, value);
  };

  return (
    <Stack
      direction={'row'}
      alignItems={'center'}
      justifyContent={justifyContent}
    >
      <InputLabel
        sx={{ width: '33%', fontWeight: '600', color: '#787878' }}
        error={isError}
      >
        {label}
      </InputLabel>
      <ToggleButtonGroup
        value={value}
        exclusive
        onChange={onHandleChange}
        aria-label={name}
      >
        {(options || DEFAULT_OPTIONS).map((item, index) => (
          <Styled.ToggleButton
            key={item.name}
            value={item.value}
            className={`${isError ? 'isError' : ''}`}
            sx={{
              height: '28px',
              borderRadius: '16px',
              width: '60px',
            }}
          >
            {item.name}
          </Styled.ToggleButton>
        ))}
      </ToggleButtonGroup>
    </Stack>
  );
}
