import { Routes, Route, Navigate } from 'react-router-dom';

import { setLocale } from 'yup';
import { useIntl } from 'react-intl';

import messages from 'src/messages';
import HomePage from 'src/pages/HomePage';
import LoginPage from 'src/pages/Auth/LoginPage';
import GatekeeperPage from 'src/pages/GatekeeperPage';
import { useRequireAuthentication } from 'src/utils/hooks/useAuthentication';

const Router = () => {
  const { formatMessage } = useIntl();

  setLocale({
    mixed: {
      required: formatMessage(messages.requiredField),
      notType: (params: any) => {
        const args = { type: params.type };
        if (params.path.toLowerCase().includes('time')) args.type = 'time';
        return formatMessage(messages.notTypeField, args);
      },
    },
    number: {
      integer: formatMessage(messages.integerField),
      positive: formatMessage(messages.positiveField),
      min: (params: any) => formatMessage(messages.minValueField, params),
      max: (params: any) => formatMessage(messages.maxValueField, params),
    },
    date: {
      min: ({ min }: any) =>
        formatMessage(messages.minDateField, { min: min.format('L') }),
      max: ({ max }: any) =>
        formatMessage(messages.maxDateField, { max: max.format('L') }),
    },
  });

  const AuthenticatedHomePage = useRequireAuthentication(HomePage);
  const AuthenticatedGatekeeperPage = useRequireAuthentication(GatekeeperPage);

  return (
    <Routes>
      <Route path="/login" element={<LoginPage />} />
      <Route path="/home" element={<AuthenticatedHomePage />} />
      <Route path="/gatekeeper" element={<AuthenticatedGatekeeperPage />} />
      <Route path="/" element={<Navigate to="/home" replace />} />
    </Routes>
  );
};

export default Router;
