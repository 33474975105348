import React, { useCallback } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import { TableFooter } from '@mui/material';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import CircularProgress from '@mui/material/CircularProgress';

import { Styled } from './index.styled';

const ROWS_PER_PAGE = 35;
const ROWS_PER_PAGE_OPTIONS = [ROWS_PER_PAGE];
export interface ColumnType<T> {
  maxWidth?: number;
  id: keyof T | string;
  label?: string | React.ReactNode;
  align?: 'right' | 'left' | 'center';
  render?: (value: T[keyof T] | {}, row: T) => string | React.ReactNode;
}

interface DataTableProps<T extends object> {
  rows: Array<T>;
  isLoading?: boolean;
  pagination?: UCM.MetaType;
  columns: Array<ColumnType<T>>;
  onPaginate: (event: unknown, newPage: number) => void;
}

export default function DataTable<T extends object>({
  rows,
  columns,
  pagination,
  onPaginate,
  isLoading = false,
}: DataTableProps<T>) {
  const { page, totalRows } = resolvePagination(pagination);

  const handleChangePage = useCallback(
    (event: unknown, newPage: number) => {
      if (typeof onPaginate === 'function') onPaginate(event, newPage);
    },
    [onPaginate],
  );

  const renderTableContent = () => {
    if (isLoading) {
      return (
        <TableRow>
          <TableCell colSpan={columns.length} align="center">
            <CircularProgress />
          </TableCell>
        </TableRow>
      );
    }

    if (rows.length === 0) {
      return (
        <TableRow>
          <TableCell colSpan={columns.length} align="center">
            No data available
          </TableCell>
        </TableRow>
      );
    }

    return rows.map((row, rowIndex) => (
      <Styled.Row key={rowIndex}>
        {columns.map((column) => {
          const value = column.id in row ? row[column.id as keyof T] : {};

          return (
            <Styled.Cell
              key={String(column.id)}
              align={column.align}
              sx={{ maxWidth: column.maxWidth }}
            >
              {column?.render ? column.render(value, row) : (value as string)}
            </Styled.Cell>
          );
        })}
      </Styled.Row>
    ));
  };

  return (
    <Paper elevation={0}>
      <Styled.Container>
        <Table stickyHeader aria-label="sticky table" style={{ flex: 1 }}>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={String(column.id)} align={column.align}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>{renderTableContent()}</TableBody>

          {rows.length !== 0 ? (
            <TableFooter>
              <TableRow>
                <TablePagination
                  page={page}
                  count={totalRows}
                  rowsPerPage={ROWS_PER_PAGE}
                  onPageChange={handleChangePage}
                  rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                />
              </TableRow>
            </TableFooter>
          ) : null}
        </Table>
      </Styled.Container>
    </Paper>
  );
}

export function resolvePagination(pagination: UCM.MetaType | undefined) {
  const page = pagination?.currentPage ? pagination.currentPage - 1 : 0;
  const totalRows = pagination?.totalData || 0;
  return { page, totalRows };
}
