import { useCallback, useEffect, useMemo, useState } from 'react';

import { useFormik } from 'formik';
import { useIntl } from 'react-intl';
import { boolean, date, number, object, string } from 'yup';

import isEmpty from 'lodash/isEmpty';
import debounce from 'lodash/debounce';

import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import ArrowDropUpOutlinedIcon from '@mui/icons-material/ArrowDropUpOutlined';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';

import {
  useGetUserAccount,
  useGetCityOptions,
  useGetCountryOptions,
  useGetEmailAvailable,
  useGetOccupationOptions,
  useGetLanguageLevelOptions,
  type UserAccountParamsType,
} from 'src/pages/GatekeeperPage/api';

import Dropdown from 'src/components/Dropdown';
import TextInput from 'src/components/TextInput';
import ConfirmModal from 'src/components/ConfirmModal';
import { DatePickerField } from 'src/components/DatePickerField';
import CustomToggleButton from 'src/components/CustomToggleButton/CustomToggleButton';

import type { Moment } from 'moment';
import { type SelectChangeEvent } from '@mui/material';

import messages from './messages';
import globalMessages from 'src/messages';
import { Styled } from './index.styled';
import { colors } from 'src/utils/customColors';

interface PreRegisterFormModalProps {
  isEdit: boolean;
  userUuid?: string;
  onCreate?: (payload: UserAccountParamsType) => void;
  onUpdate?: (
    payload: UserAccountParamsType & { candidateUuid: string },
  ) => void;
  triggerButton?: (
    handleOpen: (event: React.MouseEvent<HTMLElement>) => void,
  ) => React.ReactNode;
}

export default function PreRegisterFormModal({
  isEdit,
  onUpdate,
  onCreate,
  userUuid,
  triggerButton,
}: PreRegisterFormModalProps) {
  const { formatMessage } = useIntl();

  const [showMoreFields, setShowMoreFields] = useState(false);
  const [debouncedEmail, setDebouncedEmail] = useState<string>('');

  const { data: cityOptions } = useGetCityOptions();
  const { data: coutryOptions } = useGetCountryOptions();
  const { data: occupationOptions } = useGetOccupationOptions();
  const { data: germanLevelOptions } = useGetLanguageLevelOptions('de');
  const { data: englishLevelOptions } = useGetLanguageLevelOptions('en');
  const { data: userAccount, refetch: fetchUserAccount } = useGetUserAccount(
    userUuid ?? '',
    { enabled: false },
  );

  const initialValues: UserAccountParamsType = useMemo(
    () => ({
      email: userAccount?.email ?? '',
      lastName: userAccount?.lastName ?? '',
      mainCity: userAccount?.cityCode ?? '',
      german: userAccount?.germanLevel ?? '',
      firstName: userAccount?.firstName ?? '',
      english: userAccount?.englishLevel ?? '',
      dateOfBirth: userAccount?.birthDate ?? null,
      phoneCode: userAccount?.phone.callingCode ?? '',
      phoneNumber: userAccount?.phone.number ?? '',
      occupation: userAccount?.occupationCode ?? '',
      graduation: userAccount?.graduationYear ?? '',
      nationality: userAccount?.citizenshipCountryCode ?? '',
    }),
    [userAccount],
  );

  const formik = useFormik<UserAccountParamsType>({
    initialValues,
    validateOnChange: true,
    enableReinitialize: true,
    validationSchema: object().shape({
      german: string(),
      english: string(),
      mainCity: string(),
      lastName: string(),
      firstName: string(),
      occupation: string(),
      phoneNumber: string().test(
        'phoneNumber-required-with-phoneCode',
        formatMessage(globalMessages.requiredField),
        function (value) {
          const { phoneCode } = this.parent;
          return (!value && !phoneCode) || (value && phoneCode);
        },
      ),
      phoneCode: string().test(
        'phoneCode-required-with-phoneNumber',
        formatMessage(globalMessages.requiredField),
        function (value) {
          const { phoneNumber } = this.parent;
          return (!value && !phoneNumber) || (value && phoneNumber);
        },
      ),
      nationality: string(),
      dateOfBirth: date().nullable(),
      workPermit: boolean().nullable(),
      email: string().email().required(),
      healthCertificate: boolean().nullable(),
      graduation: number()
        .nullable()
        .test(
          'is-future-year',
          formatMessage(messages.futureYearRequired),
          (value) => {
            if (!value) return true;
            const currentYear = new Date().getFullYear();
            return value > currentYear;
          },
        ),
    }),
    onSubmit: () => {},
  });

  const { values, errors, isValid } = formik;

  const { data: emailAvailable } = useGetEmailAvailable(debouncedEmail, {
    enabled: !!debouncedEmail && !errors.email && !isEdit,
  });

  const debouncedSetEmail = useCallback(
    debounce((value: string) => {
      setDebouncedEmail(value);
    }, 1000),
    [],
  );

  useEffect(() => {
    if (emailAvailable) {
      if (emailAvailable.registrationStatus !== 'available') {
        formik.setFieldError('email', formatMessage(emailAvailable.message));
      }
    }
  }, [emailAvailable, formik.setFieldError]);

  const toggleFields = useCallback(() => {
    setShowMoreFields((prevState) => !prevState);
  }, []);

  const handleClose = useCallback(() => {
    formik.resetForm();
    setShowMoreFields(false);
  }, [formik.resetForm]);

  const handleSubmit = useCallback(async () => {
    const errors = await formik.validateForm();
    const isValid = isEmpty(errors);

    if (!isValid) throw errors;
    handleClose();

    if (typeof onCreate === 'function' && !isEdit) {
      onCreate({ ...values });
    } else if (typeof onUpdate === 'function' && isEdit) {
      onUpdate({ ...values, candidateUuid: userUuid ?? '' });
    }
  }, [isEdit, userUuid, values, onCreate, onUpdate, formik.validateForm]);

  const onChangeEventValue = useCallback(
    (
      e:
        | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
        | SelectChangeEvent<unknown>,
      name: keyof Partial<UserAccountParamsType>,
    ) => {
      const value = e.target.value;
      formik.setFieldValue(name, value);

      if (name === 'email') {
        debouncedSetEmail(value as string);
      }
    },
    [formik.setFieldValue],
  );

  const onChangeValueByName = useCallback(
    (name: string, value: string | number | Moment | null) => {
      formik.setFieldValue(name, value);
    },
    [formik.setFieldValue],
  );

  const isConfirmationRequired = useMemo(() => {
    const { email, ...otherValues } = formik.values;

    const isEmail = email.trim() !== '';

    const isAnyFieldNotEmpty = Object.values(otherValues).some(
      (value) => value != null && value !== '',
    );

    return isEmail && !isAnyFieldNotEmpty;
  }, [formik.values]);

  return (
    <ConfirmModal
      onClose={handleClose}
      onConfirm={handleSubmit}
      confirmationDialogMaxWidth="xs"
      disabled={!isValid || !values.email}
      isRequiredConfirmationPopup={isConfirmationRequired}
      confirmationMessage={
        isConfirmationRequired
          ? formatMessage(messages.confirmationMessage)
          : ''
      }
      onOpen={isEdit ? () => fetchUserAccount() : undefined}
      title={formatMessage(isEdit ? messages.editTitle : messages.createTitle)}
      triggerButton={(handleOpen) => {
        if (triggerButton != null) return triggerButton(handleOpen);

        return (
          <Button
            variant="outlined"
            onClick={handleOpen}
            sx={{ borderRadius: '16px', borderColor: colors.lightGray }}
            startIcon={<AddOutlinedIcon color="primary" />}
          >
            {formatMessage(messages.addPreRegisterButton)}
          </Button>
        );
      }}
    >
      <Stack spacing={1.5} maxHeight="75vh" overflow="auto">
        <TextInput
          name="email"
          value={values.email}
          label={formatMessage(messages.emailField)}
          onChange={(e) => onChangeEventValue(e, 'email')}
          error={!!errors.email}
          helperText={errors.email}
          EndIcon={ModeEditOutlineOutlinedIcon}
          color={
            values.email ? (errors.email ? 'error' : 'success') : undefined
          }
        />

        <Stack direction="row">
          <Grid container spacing={2} width={'100vw'}>
            <Grid size={3}>
              <TextInput
                fullWidth
                name="phoneCode"
                value={values.phoneCode}
                label={formatMessage(messages.phoneCodeField)}
                onChange={(e) => onChangeEventValue(e, 'phoneCode')}
                error={!!errors.phoneCode}
                helperText={errors.phoneCode}
              />
            </Grid>

            <Grid size={9}>
              <TextInput
                fullWidth
                name="phoneNumber"
                value={values.phoneNumber}
                label={formatMessage(messages.phoneNumberField)}
                onChange={(e) => onChangeEventValue(e, 'phoneNumber')}
                error={!!errors.phoneNumber}
                helperText={errors.phoneNumber}
                EndIcon={ModeEditOutlineOutlinedIcon}
              />
            </Grid>
          </Grid>
        </Stack>

        <TextInput
          name="firstName"
          value={values.firstName}
          label={formatMessage(messages.firstNameField)}
          onChange={(e) => onChangeEventValue(e, 'firstName')}
          error={!!errors.firstName}
          helperText={errors.firstName}
          EndIcon={ModeEditOutlineOutlinedIcon}
        />

        <TextInput
          name="lastName"
          value={values.lastName}
          label={formatMessage(messages.lastNameField)}
          onChange={(e) => onChangeEventValue(e, 'lastName')}
          error={!!errors.lastName}
          helperText={errors.lastName}
          EndIcon={ModeEditOutlineOutlinedIcon}
        />

        <Styled.ToggleButton direction="row" spacing={1} onClick={toggleFields}>
          <Typography fontSize={16}>
            {formatMessage(messages.baseProfileText)}
          </Typography>
          {showMoreFields ? (
            <ArrowDropUpOutlinedIcon />
          ) : (
            <ArrowDropDownOutlinedIcon />
          )}
        </Styled.ToggleButton>

        {showMoreFields && (
          <>
            <Stack pb={3}>
              <Dropdown
                name="german"
                value={values.german}
                placeholder={formatMessage(messages.germanField)}
                options={germanLevelOptions}
                error={!!errors.german}
                helperText={errors.german}
                onChange={(e) => onChangeEventValue(e, 'german')}
              />
            </Stack>

            <Stack pb={1}>
              <Dropdown
                name="english"
                value={values.english}
                placeholder={formatMessage(messages.englishField)}
                options={englishLevelOptions}
                error={!!errors.english}
                helperText={errors.english}
                onChange={(e) => onChangeEventValue(e, 'english')}
              />
            </Stack>

            <Stack pb={3}>
              <DatePickerField
                name="dateOfBirth"
                value={values.dateOfBirth}
                label={formatMessage(messages.dateOfBirthField)}
                onChange={(e) => onChangeValueByName('dateOfBirth', e)}
              />
            </Stack>

            <Stack pb={1}>
              <Dropdown
                name="occupation"
                value={values.occupation}
                placeholder={formatMessage(messages.occupationField)}
                options={occupationOptions}
                error={!!errors.occupation}
                helperText={errors.occupation}
                onChange={(e) => onChangeEventValue(e, 'occupation')}
              />
            </Stack>

            <Stack pb={3}>
              <TextInput
                name="graduation"
                value={values.graduation}
                label={formatMessage(messages.graduationField)}
                onChange={(e) => onChangeEventValue(e, 'graduation')}
                error={!!errors.graduation}
                helperText={errors.graduation}
                EndIcon={ModeEditOutlineOutlinedIcon}
              />
            </Stack>

            <Stack pb={3}>
              <Dropdown
                name="mainCity"
                value={values.mainCity}
                placeholder={formatMessage(messages.mainCityField)}
                options={cityOptions}
                error={!!errors.mainCity}
                helperText={errors.mainCity}
                onChange={(e) => onChangeEventValue(e, 'mainCity')}
              />
            </Stack>

            <Stack pb={2}>
              <Dropdown
                name="nationality"
                value={values.nationality}
                placeholder={formatMessage(messages.nationalityField)}
                options={coutryOptions}
                error={!!errors.nationality}
                helperText={errors.nationality}
                onChange={(e) => onChangeEventValue(e, 'nationality')}
              />
            </Stack>

            <Stack pb={1} spacing={3}>
              <CustomToggleButton
                label={formatMessage(messages.workPermitLabel)}
                name="workPermit"
                value={values.workPermit}
                onChange={onChangeValueByName}
                isError={!!errors.workPermit}
                justifyContent={'space-between'}
              />

              <CustomToggleButton
                label={formatMessage(messages.healthCertificateLabel)}
                name="healthCertificate"
                value={values.healthCertificate}
                onChange={onChangeValueByName}
                isError={!!errors.healthCertificate}
                justifyContent={'space-between'}
              />
            </Stack>
          </>
        )}
      </Stack>
    </ConfirmModal>
  );
}
