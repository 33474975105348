import { useIntl } from 'react-intl';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import { API } from 'src/reactQuery/Api';
import { putRequest } from 'src/utils/request';
import { QUERY_KEYS } from 'src/reactQuery/QueryKeys';
import { RequestSimplifiedData } from 'src/types/requestData';
import { useMessageHandler } from 'src/utils/hooks/useMessageHandler';
import messages from 'src/pages/GatekeeperPage/components/EvaluationFormModal/messages';

import type { PrescreeningParamsType } from './useCreatePrescreening';

const updatePrescreening = async ({
  uuid,
  candidateUuid,
  ...prescreenigProps
}: PrescreeningParamsType & {
  uuid: string;
  candidateUuid: string;
}) => {
  const params = {
    prescreening: {
      interview_method: prescreenigProps.interviewMethod,
      german_speaker: prescreenigProps.germanSpeaker,
      mindset: prescreenigProps.mindset,
      impression: prescreenigProps.impression,
      comprehension: prescreenigProps.comprehension,
      expression: prescreenigProps.expression,
      vetoed: prescreenigProps.vetoed,
      veto_comment: prescreenigProps.vetoComment,
    },
  };

  const { data } = await putRequest<RequestSimplifiedData>(
    API.PUT.updatePrescreening(candidateUuid, uuid),
    params,
  );

  return data;
};

export const useUpdatePrescreening = () => {
  const client = useQueryClient();
  const { formatMessage } = useIntl();
  const { handleError, handleSuccess } = useMessageHandler();

  return useMutation({
    mutationFn: updatePrescreening,
    onSuccess: ({ data: { uuid } }) => {
      handleSuccess(formatMessage(messages.preScreeningUpdated), 1000);

      client.invalidateQueries({ queryKey: [QUERY_KEYS.USER_ACCOUNTS] });
      client.invalidateQueries({ queryKey: [QUERY_KEYS.PRE_SCREENING, uuid] });
    },
    onError: handleError,
  });
};
