import React, {
  useState,
  useCallback,
  useRef,
  useEffect,
  type ReactNode,
} from 'react';

import { useIntl } from 'react-intl';
import styled from 'styled-components';

import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import messages from './messages';
import DialogContent from '@mui/material/DialogContent';

interface ConfirmDialogProps {
  onConfirm: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onClose?: () => void;
  message?: string;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  children?: (
    openDialog: (
      event: React.MouseEvent,
      message?: string,
      title?: string,
    ) => void,
  ) => ReactNode;
}

const Wrapper = styled.div`
  display: inline-block;
`;

export default function ConfirmationDialog({
  message,
  onConfirm,
  onClose,
  maxWidth,
  children,
}: ConfirmDialogProps) {
  const { formatMessage } = useIntl();

  const [dialogOpen, setDialogOpen] = useState(false);

  useEffect(() => {
    setDialogOpen(false);
  }, [open]);

  const handleClose = useCallback(() => {
    if (typeof onClose === 'function') onClose();

    setDialogOpen(false);
  }, [onClose]);

  const handleConfirm = useCallback(
    async (event: React.MouseEvent<HTMLButtonElement>) => {
      let close: boolean | void = true;

      try {
        close = await onConfirm(event);
      } catch (error) {
        console.error(error);
        close = false;
      }

      if (close !== false) handleClose();
    },
    [onConfirm, handleClose],
  );

  return (
    <Wrapper>
      {typeof children === 'function' &&
        children((_: React.MouseEvent) => setDialogOpen(true))}

      <Dialog open={dialogOpen} maxWidth={maxWidth} onClose={handleClose}>
        <DialogTitle fontSize={16}>{formatMessage(messages.title)}</DialogTitle>

        {message && <DialogContent>{message}</DialogContent>}

        <DialogActions>
          <Button
            id="cancelButton"
            color="secondary"
            variant="text"
            name="cancelButton"
            onClick={handleClose}
            sx={{ borderRadius: '16px' }}
          >
            {formatMessage(messages.cancelButtonLabel)}
          </Button>
          <Button
            id="confirmButton"
            color="secondary"
            name="confirmButton"
            onClick={handleConfirm}
            sx={{ borderRadius: '16px' }}
          >
            {formatMessage(messages.confirmButtonLabel)}
          </Button>
        </DialogActions>
      </Dialog>
    </Wrapper>
  );
}
