import React, { useCallback } from 'react';

import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

export interface PageLayoutTabProps {
  activeTab?: string;
  setActiveTab?: (tab: string) => void;
}

export interface DashboardTabProps {
  tabs?: Array<{ label: string; value: string }>;
}

export type NavTabProps = PageLayoutTabProps & DashboardTabProps;

export default function NavTabs({
  tabs,
  activeTab,
  setActiveTab,
}: NavTabProps) {
  const handleChange = useCallback(
    (_: React.SyntheticEvent, newValue: string) => {
      if (typeof setActiveTab === 'function') setActiveTab(newValue);
    },
    [setActiveTab],
  );

  if (tabs == null) return null;

  return (
    <Tabs value={activeTab ?? false} onChange={handleChange}>
      {tabs.map(({ label, value }) => (
        <Tab key={value} value={value} label={label} />
      ))}
    </Tabs>
  );
}
