import { createTheme } from '@mui/material/styles';
import { colors, opacity } from './customColors';
import * as color from './customThemeColors';
import { Theme } from '@mui/material/styles';

declare module '@mui/styles' {
  interface DefaultTheme extends Theme {}
}

const defaultTheme = createTheme({
  components: {
    MuiButton: {
      defaultProps: {
        variant: 'contained',
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          borderBottom: `1px solid ${colors.mediumGrey}`,
          color: colors.black,
          backgroundColor: colors.white,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: '12px',
          fontWeight: 600,
          fontFamily: 'Figtree',
          color: colors.darkBlue,
        },
      },
    },
    // Uncomment and customize the following as needed
    // MuiListSubheader: {
    //   styleOverrides: {
    //     root: {
    //       color: colors.darkTurquoise,
    //     },
    //     sticky: {
    //       backgroundColor: colors.lighterBlue,
    //     },
    //   },
    // },
    // MuiTablePagination: {
    //   styleOverrides: {
    //     root: {
    //       backgroundColor: colors.lighterBlue,
    //       borderRadius: 8,
    //     },
    //   },
    // },
    // MuiIconButton: {
    //   styleOverrides: {
    //     root: {
    //       '&.Mui-disabled': {
    //         color: '#e0e0e0',
    //       },
    //     },
    //   },
    // },
    // MuiChip: {
    //   styleOverrides: {
    //     root: {
    //       color: colors.darkTurquoise,
    //       backgroundColor: colors.yellow,
    //     },
    //     sizeSmall: {
    //       height: 18,
    //     },
    //     labelSmall: {
    //       fontSize: '0.6rem', // 10px
    //     },
    //   },
    // },
    // MuiTabs: {
    //   styleOverrides: {
    //     root: {
    //       color: colors.darkTurquoise,
    //       backgroundColor: colors.lighterBlue,
    //     },
    //   },
    // },
    // MuiTab: {
    //   styleOverrides: {
    //     root: {
    //       fontWeight: 500,
    //       borderWidth: 'thin',
    //       borderStyle: 'outset',
    //       borderBottomColor: 'transparent',
    //       borderRadius: 8,
    //       backgroundColor: colors.lighterBlue,
    //     },
    //     textColorInherit: {
    //       '&.Mui-selected': {
    //         backgroundColor: colors.lightBlue,
    //         opacity: 1,
    //       },
    //     },
    //   },
    // },
    // MuiExpansionPanelSummary: {
    //   styleOverrides: {
    //     root: {
    //       borderRadius: 8,
    //     },
    //   },
    // },
    // MuiListItem: {
    //   styleOverrides: {
    //     root: {
    //       '&.Mui-selected': {
    //         backgroundColor: opacity(colors.yellow, 0.12),
    //       },
    //     },
    //   },
    // },
    // MuiTableRow: {
    //   styleOverrides: {
    //     root: {
    //       '&.Mui-selected': {
    //         backgroundColor: opacity(colors.yellow, 0.12),
    //       },
    //     },
    //   },
    // },
    // MuiPickersDay: {
    //   styleOverrides: {
    //     current: {
    //       backgroundColor: colors.lighterBlue,
    //     },
    //   },
    // },
  },
  shape: {
    borderRadius: 8,
  },
  palette: {
    primary: {
      main: color.darkTurquoiseAndLightGrey.background,
      contrastText: color.darkTurquoiseAndLightGrey.text,
    },
    secondary: {
      main: color.darkBlueAndTurquoise.background,
      contrastText: color.darkBlueAndTurquoise.text,
    },
    success: {
      main: colors.green,
    },
    error: {
      main: colors.red,
    },
    text: {
      primary: colors.darkBlue,
      secondary: opacity(colors.darkBlue),
    },
    action: {
      disabled: color.disable(color.turquoiseAndLightGrey, 1.0).text,
      disabledBackground: color.disable(color.turquoiseAndLightGrey).background,
    },
  },
  typography: {
    h1: {
      fontFamily: 'Figtree',
      fontSize: '70pt',
      fontWeight: 900,
    },
    h2: {
      fontFamily: 'Figtree',
      fontWeight: 900,
    },
    h3: {
      fontFamily: 'Figtree',
      fontSize: '1.75rem', // 28px
      fontWeight: 700,
    },
    h4: {
      fontFamily: 'Figtree',
      fontSize: '1.5rem', // 24px
      fontWeight: 700,
    },
    h5: {
      fontFamily: 'Figtree',
      fontSize: '1rem',
      fontWeight: 700,
    },
    h6: {
      fontFamily: 'Figtree',
      fontSize: '0.875rem', // 14px
      fontWeight: 700,
    },
    subtitle1: {
      fontFamily: 'Figtree',
      fontSize: '0.875rem', // 14px
      fontWeight: 500,
    },
    subtitle2: {
      fontFamily: 'Figtree',
      fontSize: '0.75rem', // 12px
      fontWeight: 500,
    },
    body1: {
      fontFamily: 'Figtree',
      fontSize: '0.875rem', // 14px
    },
    body2: {
      fontFamily: 'Figtree',
      fontSize: '0.75rem', // 12px
    },
    caption: {
      fontFamily: 'Figtree',
      fontSize: '0.6rem', // 10px
    },
    button: {
      fontFamily: 'Figtree',
      textTransform: 'none',
      fontWeight: 400,
    },
  },
});

export default defaultTheme;
