/*
 * Confirm Modal Messages
 *
 * This contains all the text for the Confirm Modal component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'src.components.ConfirmationDialog';

export default defineMessages({
  confirmButtonLabel: {
    id: `${scope}.confirmButtonLabel`,
    defaultMessage: 'Confirm',
  },
  cancelButtonLabel: {
    id: `${scope}.cancelButtonLabel`,
    defaultMessage: 'Cancel',
  },
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Confirmation',
  },
});
