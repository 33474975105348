import {
  Box,
  Select,
  MenuItem,
  FormControl,
  FormHelperText,
  SelectChangeEvent,
} from '@mui/material';

interface DropdownProps {
  name: string;
  value: string;
  error?: boolean;
  helperText?: string;
  placeholder?: string;
  onChange: (event: SelectChangeEvent<string>) => void;
  options: Array<{ value: string; label: string }> | undefined;
}

export default function Dropdown({
  name,
  value,
  error,
  options,
  onChange,
  helperText,
  placeholder = 'Select an option',
}: DropdownProps) {
  if (options == null) return null;

  return (
    <FormControl fullWidth error={error}>
      <Select
        displayEmpty
        name={name}
        value={value}
        variant="standard"
        onChange={onChange}
        MenuProps={{ PaperProps: { style: { maxHeight: 500 } } }}
      >
        <MenuItem value="" disabled>
          {placeholder}
        </MenuItem>

        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      {helperText && (
        <FormHelperText sx={{ marginLeft: 0 }}>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
}
