import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useFormik } from 'formik';
import { useIntl } from 'react-intl';
import { boolean, number, object, string } from 'yup';

import isEmpty from 'lodash/isEmpty';
import debounce from 'lodash/debounce';

import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';

import {
  useGetPrescreening,
  useGetEmailAvailable,
  useGetInterviewMethodOptions,
  type PrescreeningParamsType,
} from 'src/pages/GatekeeperPage/api';

import globalMessages from 'src/messages';
import Dropdown from 'src/components/Dropdown';
import TextInput from 'src/components/TextInput';
import ConfirmModal from 'src/components/ConfirmModal';
import MiceField from 'src/components/MiceField/MiceField';
import CustomToggleButton from 'src/components/CustomToggleButton/CustomToggleButton';

import type { SelectChangeEvent } from '@mui/material/Select';

import messages from './messages';
import { colors } from 'src/utils/customColors';

interface EvaluationFormModalProps {
  uuid?: string;
  isEdit: boolean;
  userUuid?: string;
  userEmail?: string;
  onCreate?: (
    payload: PrescreeningParamsType & { candidateUuid: string },
  ) => void;
  onUpdate?: (
    payload: PrescreeningParamsType & { uuid: string; candidateUuid: string },
  ) => void;
  triggerButton?: (
    handleOpen: (event: React.MouseEvent<HTMLElement>) => void,
  ) => React.ReactNode;
}

export default function EvaluationFormModal({
  uuid,
  isEdit,
  userUuid,
  onCreate,
  onUpdate,
  userEmail,
  triggerButton,
}: EvaluationFormModalProps) {
  const { formatMessage } = useIntl();

  const [debouncedEmail, setDebouncedEmail] = useState<string>('');
  const [candidateUuid, setCandidateUuid] = useState<string>(userUuid ?? '');

  const { data: prescreeningDetail, refetch: fetchPrescreening } =
    useGetPrescreening(userUuid ?? '', uuid ?? '', { enabled: false });
  const { data: interviewMethodOptions } = useGetInterviewMethodOptions();

  const initialValues: PrescreeningParamsType = useMemo(
    () => ({
      email: userEmail != null ? userEmail : '',
      vetoComment: prescreeningDetail?.vetoComment ?? '',
      interviewMethod: prescreeningDetail?.interviewMethod ?? '',
      ...(prescreeningDetail?.vetoed != null && {
        vetoed: prescreeningDetail.vetoed,
      }),
      ...(prescreeningDetail?.germanSpeaker != null && {
        germanSpeaker: prescreeningDetail.germanSpeaker,
      }),
      ...(prescreeningDetail?.attributeScores?.mindset !== undefined && {
        mindset: prescreeningDetail.attributeScores.mindset,
      }),
      ...(prescreeningDetail?.attributeScores?.impression !== undefined && {
        impression: prescreeningDetail.attributeScores.impression,
      }),
      ...(prescreeningDetail?.attributeScores?.comprehension !== undefined && {
        comprehension: prescreeningDetail.attributeScores.comprehension,
      }),
      ...(prescreeningDetail?.attributeScores?.expression !== undefined && {
        expression: prescreeningDetail.attributeScores.expression,
      }),
    }),
    [prescreeningDetail],
  );

  const formik = useFormik<PrescreeningParamsType>({
    initialValues,
    validateOnChange: true,
    enableReinitialize: true,
    validate: (values) => {
      const errors: { vetoComment?: string } = {};

      if (values.vetoed && isEmpty(values.vetoComment)) {
        errors.vetoComment = formatMessage(globalMessages.requiredField);
      }

      return errors;
    },
    validationSchema: object().shape({
      email: string().email().required(),
      interviewMethod: string().required(),
      germanSpeaker: boolean().required(),
      mindset: number().required(),
      impression: number().required(),
      expression: number().required(),
      vetoed: boolean().required(),
      comprehension: number().required(),
      vetoComment: string(),
    }),

    onSubmit: () => {},
  });

  const { errors, values, isValid } = formik;

  const { data: emailAvailable } = useGetEmailAvailable(debouncedEmail, {
    enabled: !!debouncedEmail && !errors.email && !isEdit,
  });

  const debouncedSetEmail = useCallback(
    debounce((value: string) => {
      setDebouncedEmail(value);
    }, 1000),
    [],
  );

  useEffect(() => {
    if (emailAvailable) {
      if (emailAvailable.registrationStatus !== 'preregistered') {
        formik.setFieldError('email', formatMessage(emailAvailable.message));
      }
    }
  }, [emailAvailable, formik.setFieldError]);

  // We need to pick userUuid from useGetEmailAvailable onCreate for the the button at top
  useEffect(() => {
    if (emailAvailable != null) {
      setCandidateUuid(emailAvailable?.userUuid ?? userUuid ?? '');
    }
  }, [emailAvailable]);

  const onChangeValueByName = useCallback(
    (name: string, value: string | number) => {
      formik.setFieldValue(name, value);
    },
    [formik.setFieldValue],
  );

  const onChangeEventValue = useCallback(
    (
      e:
        | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
        | SelectChangeEvent<unknown>,
      name: keyof Partial<PrescreeningParamsType>,
    ) => {
      const value = e.target.value;
      formik.setFieldValue(name, value);

      if (name === 'email') {
        debouncedSetEmail(value as string);
      }
    },
    [formik.setFieldValue],
  );

  const handleSubmit = useCallback(async () => {
    const errors = await formik.validateForm();
    const isValid = isEmpty(errors);

    if (!isValid) throw errors;
    handleClose();

    if (typeof onCreate === 'function' && !isEdit) {
      onCreate({ candidateUuid: candidateUuid, ...values });
    } else if (typeof onUpdate === 'function' && isEdit) {
      onUpdate({ candidateUuid: userUuid ?? '', uuid: uuid ?? '', ...values });
    }
  }, [values, isEdit, uuid, userUuid, candidateUuid, onUpdate, onCreate]);

  const handleClose = useCallback(() => {
    formik.resetForm();
  }, [formik.resetForm]);

  const isButtonDisabled = useMemo(() => {
    const requiredFields = [
      'email',
      'interviewMethod',
      'germanSpeaker',
      'mindset',
      'impression',
      'expression',
      'vetoed',
      'comprehension',
    ];

    return (
      !isValid ||
      requiredFields.some(
        (field) =>
          values[field as keyof PrescreeningParamsType] === undefined ||
          values[field as keyof PrescreeningParamsType] === '',
      )
    );
  }, [values, isValid]);

  return (
    <ConfirmModal
      disabled={isButtonDisabled}
      onClose={handleClose}
      onConfirm={handleSubmit}
      isRequiredConfirmationPopup={true}
      onOpen={isEdit ? () => fetchPrescreening() : undefined}
      confirmationMessage={formatMessage(messages.confirmationMessage)}
      title={formatMessage(isEdit ? messages.editTitle : messages.createTitle)}
      triggerButton={(handleOpen) => {
        if (triggerButton != null) return triggerButton(handleOpen);

        return (
          <Button
            variant="outlined"
            onClick={handleOpen}
            color="primary"
            sx={{ borderRadius: '16px', borderColor: colors.lightGray }}
            startIcon={<AssignmentTurnedInOutlinedIcon color="secondary" />}
          >
            {formatMessage(messages.evaluationButton)}
          </Button>
        );
      }}
    >
      <Stack spacing={4}>
        <TextInput
          name="email"
          value={values.email}
          error={!!errors.email}
          label={formatMessage(messages.emailLabel)}
          onChange={(e) => onChangeEventValue(e, 'email')}
          helperText={!!errors.email ? errors.email : ''}
          color={
            values.email ? (errors.email ? 'error' : 'success') : undefined
          }
        />

        <Dropdown
          name="interviewMethod"
          value={values.interviewMethod}
          options={interviewMethodOptions}
          error={!!errors.interviewMethod}
          helperText={errors.interviewMethod}
          placeholder={formatMessage(messages.methodLabel)}
          onChange={(e) => onChangeEventValue(e, 'interviewMethod')}
        />
      </Stack>

      <Stack spacing={3} mt={3}>
        <CustomToggleButton
          name="germanSpeaker"
          value={values.germanSpeaker}
          onChange={onChangeValueByName}
          isError={!!errors.germanSpeaker}
          label={formatMessage(messages.germanSpeakerLabel)}
        />

        <MiceField
          name="mindset"
          value={values.mindset}
          isError={!!errors.mindset}
          onChange={onChangeValueByName}
          label={formatMessage(messages.mindsetLabel)}
        />

        <MiceField
          name="impression"
          value={values.impression}
          isError={!!errors.impression}
          onChange={onChangeValueByName}
          label={formatMessage(messages.impressionLabel)}
        />

        <MiceField
          name="comprehension"
          value={values.comprehension}
          onChange={onChangeValueByName}
          isError={!!errors.comprehension}
          label={formatMessage(messages.comprehensionLabel)}
        />

        <MiceField
          name="expression"
          value={values.expression}
          isError={!!errors.expression}
          onChange={onChangeValueByName}
          label={formatMessage(messages.expressionLabel)}
        />

        <CustomToggleButton
          name="vetoed"
          value={values.vetoed}
          isError={!!errors.vetoed}
          onChange={onChangeValueByName}
          label={formatMessage(messages.vetoLabel)}
          /**
           * Had to do this way because according to BE:
           *
           * vetoed: true -> No Access (Allow Access should be false)
           * vetoed: false -> Access Given (Allow Access should be true)
           */
          options={[
            { name: 'Yes', value: false },
            { name: 'No', value: true },
          ]}
        />

        {values.vetoed === true && (
          <TextInput
            name="reason"
            value={values.vetoComment}
            error={!!errors.vetoComment}
            EndIcon={ModeEditOutlineOutlinedIcon}
            helperText={errors?.vetoComment ?? ''}
            label={formatMessage(messages.vetoCommentLabel)}
            onChange={(e) => onChangeEventValue(e, 'vetoComment')}
          />
        )}
      </Stack>
    </ConfirmModal>
  );
}
