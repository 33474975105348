import styled from 'styled-components';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';

import { colors } from 'src/utils/customColors';

export const Styled = {
  ModalContainer: styled(Box)({
    width: '455px',
    padding: '16px',
    borderRadius: '24px',
    boxSizing: 'border-box',
    backgroundColor: colors.white,
  }),
  CloseContainer: styled(IconButton)({
    width: '50px',
    height: '50px',
  }),
  Separator: styled(Box)({
    width: '100%',
    height: '1px',
    marginTop: '8px',
    marginBottom: '16px',
    backgroundColor: colors.mediumGrey,
  }),
};
