import { useIntl } from 'react-intl';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import toInteger from 'lodash/toInteger';

import { API } from 'src/reactQuery/Api';
import { postRequest } from 'src/utils/request';
import { QUERY_KEYS } from 'src/reactQuery/QueryKeys';
import { useMessageHandler } from 'src/utils/hooks/useMessageHandler';
import messages from 'src/pages/GatekeeperPage/components/PreRegisterFormModal/messages';

import type { Moment } from 'moment';
import type { RequestSimplifiedData } from 'src/types/requestData';

export type UserAccountParamsType = {
  email: string;
  german: string;
  english: string;
  lastName: string;
  mainCity: string;
  firstName: string;
  occupation: string;
  workPermit?: boolean;
  graduation: string;
  phoneCode: string;
  phoneNumber: string;
  nationality: string;
  dateOfBirth: Moment | null;
  healthCertificate?: boolean;
};

const createUserAccount = async (payload: UserAccountParamsType) => {
  const params = {
    user_account: {
      email: payload.email,
      last_name: payload.lastName,
      city_code: payload.mainCity,
      german_level: payload.german,
      first_name: payload.firstName,
      english_level: payload.english,
      birth_date: payload.dateOfBirth,
      mobile_number: payload.phoneNumber,
      occupation_code: payload.occupation,
      mobile_calling_code: payload.phoneCode,
      citizenship_country_code: payload.nationality,
      graduation_year:
        payload.graduation != '' ? toInteger(payload.graduation) : null,
    },
  };
  await postRequest<RequestSimplifiedData>(
    API.POST.createPreRegistration,
    params,
  );
};

export const useCreateUserAccount = () => {
  const { formatMessage } = useIntl();
  const client = useQueryClient();
  const { handleError, handleSuccess } = useMessageHandler();

  return useMutation({
    mutationFn: createUserAccount,
    onSuccess: () => {
      handleSuccess(formatMessage(messages.preRegistrationCreated), 1000);
      client.invalidateQueries({
        queryKey: [QUERY_KEYS.USER_ACCOUNTS],
      });
    },
    onError: handleError,
  });
};
