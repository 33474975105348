import styled from 'styled-components';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { colors } from 'src/utils/customColors';

export const Styled = {
  MiceButton: styled(Box)({
    width: '23px',
    height: '20px',
    borderRadius: '6px',
    border: `1px solid ${colors.steelGrey}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: colors.lightGrey,
    },
    '&.isActive': {
      backgroundColor: colors.blue,
      borderColor: colors.blue,
    },
    '&.isError': {
      borderColor: colors.red,
    },
  }),
  Info: styled(Typography)({
    color: colors.darkGrey,
    fontSize: 8,
    fontWeight: '400',
    opacity: 0.38,
    position: 'absolute',
    bottom: -20,
  }),
};
